@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap');


.middlesection{
    font-family: 'Archivo', sans-serif;
    width: 100%;
}

#imagecontainer{
    position: relative;
    text-align: center;
}

#hero{
    width: 100%;
}

#name{
    font-family: 'Alata', sans-serif;
    color: white;
    width: 100%;
    font-size: 200px;
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* z-index: -1; */
    /* padding-bottom: 700px; */
}



#scroll-alert{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.section{
    position: relative;
    z-index: 3;
    background-color: white;
    width: 100%;
    /* margin-bottom: 100px; */
}

#about{
    margin-bottom: 30px;
}

#services{
    margin-bottom: 30px;
}

#visualize{
    margin-bottom: 30px;
}

#treat{
    margin-bottom: 30px;
}

#serve{
    margin-bottom: 30px;
}    

#policies{
    margin-bottom: 30px;
}



@media only screen and (max-width: 1050px) {
    /* #name {
      font-size: 75px;
      transform: translate(-50%, -40%);
    } */
    #hero{
        min-width: 1000px;
        transform: translate(0%,-20%);
        width: auto;
    }
  }

@media only screen and (max-width: 600px) {
    #name {
      font-size: 75px;
      transform: translate(-50%, -40%);
    }
    #hero{
        min-width: 500px;
        transform: translate(-50%,5%);
        width: auto;
    }
  }