@import url('https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap');

.header{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: black;
    color: white;
    font-family: 'Alata', sans-serif;
  }

  .hover:hover{
    color: rgb(124, 15, 103);
    transition: .5s;
  }

  #header-links{
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    list-style-type: none;
    /* margin-right: 30px; */
  }

  @media only screen and (max-width: 600px) {
    .hover {
      font-size: 15px;
    }
  }