@import url('https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap');

#policy-main{
    background-color: black;
    color: white;
    font-family: 'Alata', sans-serif;
    display: flex;
    flex-direction: row;
    padding: 30px;
    
}

#policy-left{
    width: 15%;
}

#policy-center{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    
}
#policy-right{
    width: 15%;
}


a:link {
    text-decoration: none!important;
    color: white!important;    
  }

  a.active {
    color: rgb(139, 19, 115);
  }

  a:visited {
    color: rgb(78, 24, 83);;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:hover {
    color: rgb(146, 23, 23);;
    background-color: transparent;
    text-decoration: underline;}

#policy-header{
    /* font-size: 22px;
    padding-bottom: 20px; */

    font-family: 'Alata', sans-serif;
    font-size:28px;
    padding-top: 20px;
    text-align: center;
    background-color: black;
    color: white;
    
}

@media only screen and (max-width: 600px) {
    #policy-left{
        width: 5%;
    }
    #policy-center-left{
        width: 45%;
        margin-right: 10px;
    }
    #policy-center-right{
        width: 45%;
        margin-left: 25px;
    }
    #policy-right{
        width: 5%;
    }
  }


