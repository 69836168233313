.App {
    overflow-x: hidden;
}

#ageCheck{
    /* border: solid red 5px; */
    z-index: 4;
    width: 100%;
    height: 3000px;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: rgba(0, 0, 0, 0.9)
}

#ageCheckText{
  margin-top: 300px;
    display: flex;
    align-items: center;
     justify-content: center;
     flex-direction: column;
    text-align: center
}

#yesButton{
    margin-top: 30px;
    padding: 20px;
    border: solid 2px white;
    border-radius: 10px;
    transition: box-shadow .3s;
    float: left;
    min-width: 100px;
    text-align: left;

  }
#yesButton:hover {
    box-shadow: 0 0 11px rgba(255, 255, 255, 2); 
  }

#ageCheckCopy{
    margin-top: 20px;
}

  #jabroni{
    margin-top: 100px;
    width: 400px;
  }