@import url(https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(139, 19, 115);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: black;
    color: white;
    font-family: 'Alata', sans-serif;
  }

  .hover:hover{
    color: rgb(124, 15, 103);
    transition: .5s;
  }

  #header-links{
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    list-style-type: none;
    /* margin-right: 30px; */
  }

  @media only screen and (max-width: 600px) {
    .hover {
      font-size: 15px;
    }
  }
.middlesection{
    font-family: 'Archivo', sans-serif;
    width: 100%;
}

#imagecontainer{
    position: relative;
    text-align: center;
}

#hero{
    width: 100%;
}

#name{
    font-family: 'Alata', sans-serif;
    color: white;
    width: 100%;
    font-size: 200px;
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* z-index: -1; */
    /* padding-bottom: 700px; */
}



#scroll-alert{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.section{
    position: relative;
    z-index: 3;
    background-color: white;
    width: 100%;
    /* margin-bottom: 100px; */
}

#about{
    margin-bottom: 30px;
}

#services{
    margin-bottom: 30px;
}

#visualize{
    margin-bottom: 30px;
}

#treat{
    margin-bottom: 30px;
}

#serve{
    margin-bottom: 30px;
}    

#policies{
    margin-bottom: 30px;
}



@media only screen and (max-width: 1050px) {
    /* #name {
      font-size: 75px;
      transform: translate(-50%, -40%);
    } */
    #hero{
        min-width: 1000px;
        -webkit-transform: translate(0%,-20%);
                transform: translate(0%,-20%);
        width: auto;
    }
  }

@media only screen and (max-width: 600px) {
    #name {
      font-size: 75px;
      -webkit-transform: translate(-50%, -40%);
              transform: translate(-50%, -40%);
    }
    #hero{
        min-width: 500px;
        -webkit-transform: translate(-50%,5%);
                transform: translate(-50%,5%);
        width: auto;
    }
  }
#about-main{
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    font-family: 'Alata', sans-serif;
    font-size: 17px;
    
}

#about-left{
    width: 15%;
}               

#about-right{
    width: 15%;
}

#about-center{
    /* border: 5px green solid; */
    width: 70%;
    display: flex;
    flex-direction: row;
}

#about-center-left{
    width: 50%;
}

#about-center-right{
    width: 50%;
}

#about-text{
    padding: 30px;
    line-height: 27px;
}

#desktop-about-image{
    margin: 30px;
    max-width: 500px;
    max-height: 500px;
    border-radius: 20px;
}

#mobile-about-image{
    margin: 20px;
    max-width: 300px;
    max-height: 300px;
    display: none;
    border-radius: 15px;
}

#intro-line{
    font-size: 22px;
}

@media only screen and (max-width: 600px) {
    
    #about-main{
        flex-direction: column;
        align-items: center;
    }

    #desktop-about-image{
        display: none;
    }
    
    #mobile-about-image{
        display: unset;
    }

    #about-center-left{
        width: 100%;
    }
    
    #about-center-right{
        width: 0%;
    }
    #about-text{
        padding: 0px;
        padding-bottom: 20px;
        line-height: 27px;

    }
    
  }
#treat-me-main{
display: flex;
flex-direction: row;
background-color: black;
color: white;
font-family: 'Alata', sans-serif;
padding: 30px;
}


#treat-me-center-left{
    font-size: 15px;
    width: 25%;
}

#mobile-styler{
    width: 100%;
    display: flex;
    flex-direction: row;
}

#treat-me-center-right{
    font-size: 15px;
    width: 50%;
}


#treat-me-left{
    width: 15%;
}

#treat-me-right{
    width: 15%;
}

#treat-me-header{
    font-family: 'Alata', sans-serif;
    font-size:22px;
    padding-top: 20px;
    text-align: center;
    background-color: black;
    color: white;
}


#price-list{
    display: flex;
flex-direction: row;
}

#prices{
    list-style-type: none;
}

#incall-details{
    margin-right: 20px;
}

@media only screen and (max-width: 600px) {
    #treat-me-main{
        /* flex-direction: column; */
        padding: 0px;
        padding-bottom: 20px;
        padding-top: 10px;
        }
        
        #mobile-styler{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        #treat-me-center-left{
            width: 75%;
        }
        
        #treat-me-center-right{
            font-size: 15px;
            width: 75%;
        }
    
    
        #treat-me-left{
            width: 0%;
        }
        
        #treat-me-right{
            width: 0%;
        }

        .treat-list-item{
            list-style-type: none;
        }

        #price-list{
            margin-left: 50px;
        }

        .cityname{
            font-style: italic;
        }
  }



#book-me-main{
display: flex;
flex-direction: row;
background-color: black;
color: white;
font-family: 'Alata', sans-serif;
padding: 30px;
}


#book-me-center-left{
    font-size: 15px;
    width: 25%;
}

#mobile-styler{
    width: 100%;
    display: flex;
    flex-direction: row;
}

#book-me-center-right{
    font-size: 15px;
    width: 50%;
    text-align: center
}


#book-me-left{
    width: 15%;
}

#book-me-right{
    width: 15%;
}

#book-me-header{
    font-family: 'Alata', sans-serif;
    font-size:22px;
    padding-top: 20px;
    text-align: center;
    background-color: black;
    color: white;
}


@media only screen and (max-width: 600px) {
    #book-me-main{
        /* flex-direction: column; */
        padding: 0px;
        padding-bottom: 20px;
        padding-top: 10px;
        }
        
        #mobile-styler{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        #book-me-center-left{
            width: 75%;
        }
        
        #book-me-center-right{
            font-size: 15px;
            width: 75%;
        }
    
    
        #book-me-left{
            width: 0%;
        }
        
        #book-me-right{
            width: 0%;
        }

        .book-list-item{
            list-style-type: none;
        }
  }



#policy-main{
    background-color: black;
    color: white;
    font-family: 'Alata', sans-serif;
    display: flex;
    flex-direction: row;
    padding: 30px;
    
}

#policy-left{
    width: 15%;
}

#policy-center{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    
}
#policy-right{
    width: 15%;
}


a:link {
    text-decoration: none!important;
    color: white!important;    
  }

  a.active {
    color: rgb(139, 19, 115);
  }

  a:visited {
    color: rgb(78, 24, 83);;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:hover {
    color: rgb(146, 23, 23);;
    background-color: transparent;
    text-decoration: underline;}

#policy-header{
    /* font-size: 22px;
    padding-bottom: 20px; */

    font-family: 'Alata', sans-serif;
    font-size:28px;
    padding-top: 20px;
    text-align: center;
    background-color: black;
    color: white;
    
}

@media only screen and (max-width: 600px) {
    #policy-left{
        width: 5%;
    }
    #policy-center-left{
        width: 45%;
        margin-right: 10px;
    }
    #policy-center-right{
        width: 45%;
        margin-left: 25px;
    }
    #policy-right{
        width: 5%;
    }
  }



#visualize-container{
    background-color: black;
    display: flex;
    flex-direction: row;
    padding: 30px;
}

#visualize-me-header{
    color: white;
    font-family: 'Alata', sans-serif;
    font-size:22px;
    text-align: center;
    margin-bottom: 20px;
}

#visualize-left{width: 25%;}

#carousel-container{width: 50%;}

#visualize-right{width: 25%;}

.portrait{
    max-height: 400px;
    max-width: 300px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover
}

.landscape{
    max-height: 400px;
    max-width: 500px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover
}

@media only screen and (max-width: 600px) {
    
    #visualize-left{width: 0%;}

    #carousel-container{width: 100%;}

    #visualize-right{width: 0%;}

  }



.App {
    overflow-x: hidden;
}

#ageCheck{
    /* border: solid red 5px; */
    z-index: 4;
    width: 100%;
    height: 3000px;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: rgba(0, 0, 0, 0.9)
}

#ageCheckText{
  margin-top: 300px;
    display: flex;
    align-items: center;
     justify-content: center;
     flex-direction: column;
    text-align: center
}

#yesButton{
    margin-top: 30px;
    padding: 20px;
    border: solid 2px white;
    border-radius: 10px;
    transition: box-shadow .3s;
    float: left;
    min-width: 100px;
    text-align: left;

  }
#yesButton:hover {
    box-shadow: 0 0 11px rgba(255, 255, 255, 2); 
  }

#ageCheckCopy{
    margin-top: 20px;
}

  #jabroni{
    margin-top: 100px;
    width: 400px;
  }
