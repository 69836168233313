@import url('https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap');

#book-me-main{
display: flex;
flex-direction: row;
background-color: black;
color: white;
font-family: 'Alata', sans-serif;
padding: 30px;
}


#book-me-center-left{
    font-size: 15px;
    width: 25%;
}

#mobile-styler{
    width: 100%;
    display: flex;
    flex-direction: row;
}

#book-me-center-right{
    font-size: 15px;
    width: 50%;
    text-align: center
}


#book-me-left{
    width: 15%;
}

#book-me-right{
    width: 15%;
}

#book-me-header{
    font-family: 'Alata', sans-serif;
    font-size:22px;
    padding-top: 20px;
    text-align: center;
    background-color: black;
    color: white;
}


@media only screen and (max-width: 600px) {
    #book-me-main{
        /* flex-direction: column; */
        padding: 0px;
        padding-bottom: 20px;
        padding-top: 10px;
        }
        
        #mobile-styler{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        #book-me-center-left{
            width: 75%;
        }
        
        #book-me-center-right{
            font-size: 15px;
            width: 75%;
        }
    
    
        #book-me-left{
            width: 0%;
        }
        
        #book-me-right{
            width: 0%;
        }

        .book-list-item{
            list-style-type: none;
        }
  }


