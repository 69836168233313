@import url('https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap');

#visualize-container{
    background-color: black;
    display: flex;
    flex-direction: row;
    padding: 30px;
}

#visualize-me-header{
    color: white;
    font-family: 'Alata', sans-serif;
    font-size:22px;
    text-align: center;
    margin-bottom: 20px;
}

#visualize-left{width: 25%;}

#carousel-container{width: 50%;}

#visualize-right{width: 25%;}

.portrait{
    max-height: 400px;
    max-width: 300px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover
}

.landscape{
    max-height: 400px;
    max-width: 500px;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover
}

@media only screen and (max-width: 600px) {
    
    #visualize-left{width: 0%;}

    #carousel-container{width: 100%;}

    #visualize-right{width: 0%;}

  }


