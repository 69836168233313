@import url('https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap');

#about-main{
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    font-family: 'Alata', sans-serif;
    font-size: 17px;
    
}

#about-left{
    width: 15%;
}               

#about-right{
    width: 15%;
}

#about-center{
    /* border: 5px green solid; */
    width: 70%;
    display: flex;
    flex-direction: row;
}

#about-center-left{
    width: 50%;
}

#about-center-right{
    width: 50%;
}

#about-text{
    padding: 30px;
    line-height: 27px;
}

#desktop-about-image{
    margin: 30px;
    max-width: 500px;
    max-height: 500px;
    border-radius: 20px;
}

#mobile-about-image{
    margin: 20px;
    max-width: 300px;
    max-height: 300px;
    display: none;
    border-radius: 15px;
}

#intro-line{
    font-size: 22px;
}

@media only screen and (max-width: 600px) {
    
    #about-main{
        flex-direction: column;
        align-items: center;
    }

    #desktop-about-image{
        display: none;
    }
    
    #mobile-about-image{
        display: unset;
    }

    #about-center-left{
        width: 100%;
    }
    
    #about-center-right{
        width: 0%;
    }
    #about-text{
        padding: 0px;
        padding-bottom: 20px;
        line-height: 27px;

    }
    
  }