@import url('https://fonts.googleapis.com/css2?family=Alata&family=Archivo:wght@400;700&display=swap');

#treat-me-main{
display: flex;
flex-direction: row;
background-color: black;
color: white;
font-family: 'Alata', sans-serif;
padding: 30px;
}


#treat-me-center-left{
    font-size: 15px;
    width: 25%;
}

#mobile-styler{
    width: 100%;
    display: flex;
    flex-direction: row;
}

#treat-me-center-right{
    font-size: 15px;
    width: 50%;
}


#treat-me-left{
    width: 15%;
}

#treat-me-right{
    width: 15%;
}

#treat-me-header{
    font-family: 'Alata', sans-serif;
    font-size:22px;
    padding-top: 20px;
    text-align: center;
    background-color: black;
    color: white;
}


#price-list{
    display: flex;
flex-direction: row;
}

#prices{
    list-style-type: none;
}

#incall-details{
    margin-right: 20px;
}

@media only screen and (max-width: 600px) {
    #treat-me-main{
        /* flex-direction: column; */
        padding: 0px;
        padding-bottom: 20px;
        padding-top: 10px;
        }
        
        #mobile-styler{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        #treat-me-center-left{
            width: 75%;
        }
        
        #treat-me-center-right{
            font-size: 15px;
            width: 75%;
        }
    
    
        #treat-me-left{
            width: 0%;
        }
        
        #treat-me-right{
            width: 0%;
        }

        .treat-list-item{
            list-style-type: none;
        }

        #price-list{
            margin-left: 50px;
        }

        .cityname{
            font-style: italic;
        }
  }


